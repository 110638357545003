import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import {
  RuleSelectWrapper,
  RuleSelectedOptionWrapper,
  RuleSelectorIconWrapper,
  RuleSelectorClearIcon,
  RuleSelectorOptionsWrapper,
  RuleSelectorOptionsList,
} from "./RuleSelector.styled";
import { Icon } from "../../../../../elements";

const RuleSelector = ({
  suffix = "",
  defaultValue,
  options,
  onSelect,
  onClear,
  dataTestId,
}) => {
  const [openOptions, setOpenOptions] = useState(false);
  const [value, setValue] = useState("");
  const wrapperRef = useRef(null);

  useEffect(() => {
    setValue(defaultValue);
  }, [defaultValue]);

  const onHandleSelect = useCallback(
    (option) => {
      setValue(option.value);
      setOpenOptions(false);
      onSelect && onSelect(option);
    },
    [onSelect]
  );

  const onHandleClear = useCallback(() => {
    setValue("");
    onClear && onClear();
  }, [onClear]);

  const onHandleClick = useCallback(() => {
    setOpenOptions((prev) => !prev);
  }, []);

  const optionsFiltered = useMemo(() => {
    return options || [];
  }, [options]);

  const handleClickOutside = useCallback((event) => {
    if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
      setOpenOptions(false);
    }
  }, []);

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [handleClickOutside]);

  return (
    <RuleSelectWrapper ref={wrapperRef} data-testid={dataTestId}>
      <RuleSelectedOptionWrapper>
        {suffix && suffix}
        <span>
          {options?.find((option) => value === option.value)?.name || ""}
        </span>
      </RuleSelectedOptionWrapper>
      {value && (
        <RuleSelectorClearIcon onClick={onHandleClear}>✕</RuleSelectorClearIcon>
      )}
      <RuleSelectorIconWrapper onClick={onHandleClick}>
        <Icon name={"arrowDown"} width={"10px"} height={"10px"} />
      </RuleSelectorIconWrapper>
      {openOptions && (
        <RuleSelectorOptionsWrapper>
          <RuleSelectorOptionsList>
            {optionsFiltered.map((option, index) => (
              <p key={index} onClick={() => onHandleSelect(option)}>
                {option.name}
              </p>
            ))}
          </RuleSelectorOptionsList>
        </RuleSelectorOptionsWrapper>
      )}
    </RuleSelectWrapper>
  );
};

export default RuleSelector;

import styled from "styled-components";
import { FormControl, Fab, Drawer, Paper, InputLabel } from "@mui/material";
import TextareaAutosize from "@mui/material/TextareaAutosize";

const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 1200;
`;

const StyledPaper = styled(Paper)`
  && {
    background-color: ${(props) => props.theme.colors.highClearGray};
    padding: 1rem;
  }

  && {
    & > ul {
      border-radius: 8px;
      background-color: ${(props) => props.theme.colors.white};
      border: 1px solid ${(props) => props.theme.colors.clearGray};
    }
  }

  && {
    & ul > li {
      border-bottom: 1px solid ${(props) => props.theme.colors.clearGray};
      font-family: "Inter", sans-serif;
    }
  }

  && {
    & ul > li:last-child {
      border-bottom: none;
    }
  }
`;

const DrawerContainer = styled.form`
  height: 100%;
  display: flex;
  flex-direction: column;
  && {
    font-family: "Inter", sans-serif;
  }

  & > form {
    height: 100%;
  }
`;

const StyledDrawer = styled(Drawer)`
  && .MuiDrawer-paper {
    width: ${(props) => (props.isMobile ? "90%" : "698px")};
    background-color: white;
    height: calc(100% - 48px);
    top: 48px;
    position: fixed;
    z-index: 1300;
  }
`;

const DrawerHeader = styled.div`
  padding: 8px 32px;
  border-bottom: 1px solid ${(props) => props.theme.colors.clearGray};
  height: 56px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;

  & > div:first-child {
    font-size: 14px;
    font-weight: 600;
  }
`;

const ContentContainer = styled.div`
  padding: 16px 32px;
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  gap: 16px;
  overflow-y: auto;
`;

const StyledFormControl = styled(FormControl)`
  && {
    margin: 0;
    min-width: 40px;
    background: ${(props) => props.theme.colors.primaryWhite};
    border-radius: 8px;
    font-family: "Inter", sans-serif;
  }

  && .MuiOutlinedInput-root {
    border-radius: 8px;
    font-family: "Inter", sans-serif;
  }
`;

const StyledTextArea = styled(TextareaAutosize)`
  && {
    background: ${(props) => props.theme.colors.primaryWhite};
    border: 1px solid ${(props) => props.theme.colors.clearGray};
    border-radius: 8px;
    padding: 16px;
    font-family: "Inter", sans-serif;
    resize: none;
    flex-grow: 1;
    min-height: 200px;
    overflow-y: auto;
    scrollbar-width: thin;
  }

  &&:focus {
    outline: none;
  }
`;

const NotesFab = styled(Fab)`
  && {
    position: "relative";
    bottom: "0";
    right: "2px";
    transition: transform 0.3s ease-in-out;
    background-color: ${(props) => props.theme.colors.white};
    border: 2px solid ${(props) => props.theme.colors.activeBlue};
    width: 30px;
    height: 30px;
    min-width: 30px;
    min-height: 30px;
    display: ${(props) => (props.$isOpen ? "none" : "flex")};
    align-items: center;
    justify-content: center;
    z-index: 1301;
  }

  & > .MuiSvgIcon-root {
    display: none;
  }
`;

const StyledFab = styled(Fab)`
  && {
    position: fixed;
    bottom: 24px;
    right: 24px;
    top: ${(props) => (props.$isOpen ? "93%" : "88%")};
    transition: transform 0.3s ease-in-out, opacity 0.3s ease-in-out,
      visibility 0.3s ease-in-out;
    opacity: ${(props) => (props.$isOpen ? 1 : 0)};
    visibility: ${(props) => (props.$isOpen ? "visible" : "hidden")};
    transform: translateX(
      ${(props) =>
        props.$isOpen ? (props.isMobile ? "-315px" : "-698px") : "0"}
    );
    background-color: ${(props) => props.theme.colors.white};
    border: 2px solid ${(props) => props.theme.colors.activeBlue};
    width: ${(props) => (props.$isOpen ? "48px" : "30px")};
    height: ${(props) => (props.$isOpen ? "48px" : "30px")};
    min-width: ${(props) => (props.$isOpen ? "48px" : "30px")};
    min-height: ${(props) => (props.$isOpen ? "48px" : "30px")};
    align-items: center;
    justify-content: center;
    z-index: 1301;
  }

  & > .MuiSvgIcon-root {
    display: none;
  }
`;

const CloseIcon = styled.div`
  && {
    position: absolute;
    top: -8px;
    right: -8px;
    border-radius: 50%;
    padding: 2px;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 28px;
    height: 28px;
    cursor: pointer;
    z-index: 1301;
  }
`;

const DrawerFooter = styled.div`
  padding: 16px 32px;
  display: flex;
  justify-content: flex-end;
  && {
    font-family: "Inter", sans-serif;
  }
`;

const FromContainer = styled.div`
  font-size: 14px;
  font-family: "Inter", sans-serif;

  & > span {
    color: ${(props) => props.theme.colors.shadowGargoyle};
  }
`;

const StyledInputLabel = styled(InputLabel)`
  && {
    padding: 0;
    font-family: "Inter", sans-serif;
  }
`;

const NotesLengthWrapper = styled.div`
  font-size: 12px;
  font-family: "Inter", sans-serif;
  span {
    color: ${({ isMaxLength, theme }) =>
      isMaxLength ? theme.colors.red : "inherit"};
  }
`;

export {
  Overlay,
  StyledPaper,
  DrawerContainer,
  StyledDrawer,
  DrawerHeader,
  ContentContainer,
  StyledFormControl,
  StyledTextArea,
  StyledFab,
  NotesFab,
  CloseIcon,
  DrawerFooter,
  FromContainer,
  StyledInputLabel,
  NotesLengthWrapper,
};

import styled, { css } from "styled-components";
import { DefaultTheme as theme } from "../../../../theme";

export const RuleHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;

  p {
    display: inline-block;
    font-weight: 600;
  }

  span {
    display: flex;
    align-items: center;
    gap: 8px;
  }
`;

export const RuleSeparator = styled.div`
  display: flex;
  gap: 16px;
  justify-content: space-between;
  align-items: center;

  div {
    width: 49px;
    height: 24px;
    background-color: ${(props) => props.theme.colors.white};
    color: ${(props) => props.theme.colors.darkGray};
    border: 1px solid ${(props) => props.theme.colors.darkGray};
    font-size: 0.75em;
    border-radius: 8px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  span {
    border: 1px solid ${(props) => props.theme.colors.clearGray};
    border-radius: 8px;
    flex: 1;
  }
`;

export const HeaderWrapper = styled.div`
  display: flex;
  gap: 10px;
  button {
    font-weight: 400;
  }
`;

export const FooterWrapper = styled(HeaderWrapper)`
  margin-top: 16px;
  display: flex;
  flex-direction: row;
  gap: 1rem;
  justify-content: space-between;
`;

export const FiltersDivision = styled.div`
  display: flex;
  flex-direction: row;
`;

export const FiltersGenerator = styled.div`
  display: flex;
  flex-direction: column;
  width: 334px;
  padding-right: 10px;
  overflow-x: hidden;
  min-height: 90vh;
  ${(props) =>
    props.showRightPreview &&
    css`
      border-right: 1px solid ${theme.colors.silverGrayTwo};
    `}
`;

export const FiltersVisualization = styled.div`
  display: flex;
  flex-direction: column;
  gap: 5px;
  margin-inline: 5px;
  width: 334px;
  margin-top: 5px;
  padding-inline-start: 12px;
`;

export const Label = styled.div`
  text-align: center;
  font-weight: bold;
`;

export const FilterDivisor = styled.div`
  display: flex;
  flex-direction: column;
  min-width: 100%;
`;

export const FilterLabel = styled.div`
  margin-bottom: "5px";
`;

export const RulesViewWrapper = styled.div`
  position: absolute;
  top: 76px;
  background-color: blue;
  right: 0;
  min-width: ${(props) => (props?.showRightPreview ? "669px" : "310px")};
  background-color: ${(props) => props.theme.colors.white};
  border: 1px solid ${(props) => props.theme.colors.clearGray};
  height: calc(100% - 76px);
  overflow-y: scroll;
  z-index: 2;
  &::-webkit-scrollbar {
    width: 5px;
  }

  &::-webkit-scrollbar-track {
    background: ${(props) => props.theme.colors.primaryWhite};
  }

  &::-webkit-scrollbar-thumb {
    background: ${(props) => props.theme.colors.clearGray};
  }

  > p {
    font-weight: 600;
    padding: 10px;
    margin: 0;
    border-bottom: 1px solid ${(props) => props.theme.colors.clearGray};
  }

  > div {
    padding-block: 5px;
    padding-inline: 16px;
  }
`;

import styled, { css } from "styled-components";
import { Avatar } from "@mui/material";

const ChatWindowComponentPaper = styled.div`
  overflow-y: auto;
  padding-inline: 16px;
  padding-block-start: 16px;
  box-shadow: ${(props) => props.boxShadow || "none"} !important;
  display: flex;
  flex-direction: column;
  align-items: center;
  scrollbar-width: thin;
  max-height: ${(props) => {
    if (props.isMobile) {
      return props.chatWindowHeight === 48 ? "100%" : "90%";
    }

    if (props.isDesktop) {
        return props.chatWindowHeight === 48 ? "66vh" : "60vh";
    }
    
    if (props.isIpadProLandscape) {
      return props.chatWindowHeight === 48 ? "70vh" : "61vh";
    }
    if (props.isIpadProPortrait) {
        return props.chatWindowHeight === 48 ? "76vh" : "72vh";
    }
    if (props.isIpadAirLandscape) {
        return props.chatWindowHeight === 48 ? "66vh" : "54vh";
    }
    if (props.isIpadAirPortrait) {
        return props.chatWindowHeight === 48 ? "74vh" : "70vh";
    }
    return "72vh"
  }};
  height: ${(props) => {
    if (props.isMobile) {
      return props.chatWindowHeight === 48 ? "100%" : "86.5%";
    }
    if (props.isDesktop || props.isIpadProLandscape || props.isIpadProPortrait || props.isIpadAirLandscape || props.isIpadAirPortrait) {
        return "100%";
    }
    return props.chatWindowHeight === 48 ? "95%" : "77%";
  }};
`;

const ChatWindowAvatar = styled(Avatar)`
  margin-right: 12px;
`;

const MessageContainer = styled.div`
  margin-bottom: 12px;
  display: flex;
  width: ${(props) => props.width || "60%"};
`;

const flexWrapperStyles = css`
  display: flex;
`;

const normalWrapperStyles = css`
  display: block;
`;

const MessageWrapper = styled.div`
  padding: 0 12px;
  border-radius: 12px;
  width: 100%;
  background-color: ${(props) =>
    props.itemType === "sent" ? props.theme.colors.white : "transparent"};
  color: ${(props) => props.theme.colors.black};
  margin-bottom: 5px;
  font-size: 1rem;
  font-family: "Inter", sans-serif;

  > * {
    margin: 0;
  }

  ${(props) => (props.flex ? flexWrapperStyles : normalWrapperStyles)}
`;

const MessageButtonsWrapper = styled.div`
  display: flex;
  gap: 10px;
  margin-top: ${(props) => props.mt || "20px"};
  align-items: center;

  a {
    cursor: pointer;
  }
`;

const EditMessageWrapper = styled.div`
  padding: 12px;
  border-radius: 12px;
  width: 100%;
  color: ${(props) => props.theme.colors.primary};
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 30px;

  > * {
    margin: 0;
  }
`;

const EditMessageText = styled.div`
  ${(props) => props.theme.fonts.variant.light};
  font-size: ${(props) => props.theme.fonts.size.medium};
  color: ${(props) => props.theme.colors.black};
  text-align: center;
`;

const EditMessageInput = styled.textarea`
  resize: none;
  padding: 5px 10px;
  border-radius: 10px;

  &:focus {
    outline: none;
  }
`;
const EditMessageInputWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  color: ${(props) => props.theme.colors.primary};
`;

const SpinnerImage = styled.img`
  height: 30px;
  margin-left: 5px;
  opacity: 0.5;
`;

const MessageInfo = styled.p`
  margin: 0;
  color: ${(props) => props.theme.colors.davysGrey};
  font-size: 0.9em;
`;

export {
  ChatWindowComponentPaper,
  ChatWindowAvatar,
  MessageContainer,
  MessageWrapper,
  MessageButtonsWrapper,
  EditMessageWrapper,
  EditMessageText,
  EditMessageInput,
  EditMessageInputWrapper,
  SpinnerImage,
  MessageInfo,
};

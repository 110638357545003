import { useCallback, useState, useMemo, useEffect } from "react";
import { IconButton } from "../../../../../elements";
import RuleSelector from "./RuleSelector";
import TagsComponent from "./RuleTagsInput";
import {
  RuleEngineWrapper,
  RuleEngineRow,
  CloseButtonWrapper,
  ConditionWrapper,
} from "./RuleEngine.styled";
import RuleInput from "./RuleInput";
import { OperatorsEnum } from "./consts";
import { DateTimePicker, TimePicker } from "@mui/x-date-pickers";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { TextField } from "@mui/material";
import dayjs from "dayjs";
import { useMediaQueries } from "../../../../../utils/mediaQueries";

const DateTimePickerCustom = ({ dataTestId, type = "DATE", ...props }) => {
  const slots = { textField: TextField };
  const slotProps = {
    inputAdornment: { position: "start" },
    textField: { fullWidth: true, "data-testid": dataTestId },
  };
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      {type === "DATE" ? (
        <DateTimePicker slots={slots} slotProps={slotProps} {...props} />
      ) : (
        <TimePicker slots={slots} slotProps={slotProps} {...props} />
      )}
    </LocalizationProvider>
  );
};

const RuleEngine = ({
  ruleOperator,
  index,
  onClose,
  fields,
  onChange,
  rule,
  className = "",
  deleteIcon = "close",
  dataTestId,
  showClearButton = false,
  showConditionWrapper = false,
}) => {
  const listDestine = useMemo(
    () => [
      {
        name: "Variable",
        value: "VARIABLE",
      },
      {
        name: "Constant",
        value: "CONSTANT",
      },
    ],
    []
  );

  const [sourceData, setSourceData] = useState("");
  const [sourceField, setSourceField] = useState("");
  const [operator, setOperator] = useState("");
  const [targetType, setTargetType] = useState("");
  const [targetValue, setTargetValue] = useState("");
  const [targetData, setTargetData] = useState("");
  const { isMobile, isTablet } = useMediaQueries();

  useEffect(() => {
    setSourceData(rule?.source_data || "");
    setSourceField(rule?.source_field || "");
    setOperator(rule?.operator || "");
    setTargetType(rule?.target_type || "");
    setTargetValue(rule?.target_value || "");
    setTargetData(rule?.target_data || "");
  }, [rule]);

  const formatDateTime = (timeObj, type) => {
    return type === "TIME" ? timeObj.format("HH:mm") : timeObj.toISOString();
  };

  const formatReceiveDateTime = (timeStr, type) => {
    if (!timeStr) return dayjs();
    if (type !== "TIME") return dayjs(timeStr);
    const [hour, minute] = timeStr.split(":");
    return dayjs().hour(hour).minute(minute);
  };

  const origins = useMemo(
    () => (fields ? fields.map((field) => ({ name: field.name, value: field.origin })) : []),
    [fields]
  );

  const filteredFields = useMemo(() => {
    const selectedOrigin = fields?.find((field) => field.origin === sourceData);
    if (selectedOrigin) {
      return selectedOrigin.fields.map((field) => ({
        name: field.name,
        value: field.field,
        field_type: field.field_type,
        operators: field.operators,
        default_values: field.default_values,
      }));
    }
    return [];
  }, [sourceData, fields]);

  const filteredFieldsVariable = useMemo(() => {
    const selectedOrigin = fields?.find((field) => field.origin === targetData);
    if (selectedOrigin) {
      return selectedOrigin.fields.map((field) => ({
        name: field.name,
        value: field.field,
        field_type: field.field_type,
        operators: field.operators,
        default_values: field.default_values,
      }));
    }
    return [];
  }, [targetData, fields]);

  const fieldType = useMemo(() => {
    if (filteredFields.length > 0 && sourceField !== "") {
      const matchedField = filteredFields.find((field) => field.value === sourceField);
      return matchedField ? matchedField.field_type : "";
    }
    return "";
  }, [sourceField, filteredFields]);

  const filterOperators = useMemo(() => {
    if (filteredFields.length > 0 && sourceField !== "") {
      const matchedField = filteredFields.find((field) => field.value === sourceField);
      return matchedField && matchedField.operators
        ? matchedField.operators.map((op) => ({
          name: OperatorsEnum[op],
          value: op,
        }))
        : [];
    }
    return [];
  }, [filteredFields, sourceField]);


  const onHandleClose = () => {
    const totalConditions = ruleOperator?.length || 0;
    if (totalConditions <= 1) {
      return;
    }
    onClose && onClose(index);
  };

  const onHandleOnChange = useCallback(() => {
    onChange &&
      onChange({
        index,
        data: {
          type: "CONDITION",
          source_data: sourceData,
          source_field: sourceField,
          field_type: fieldType,
          operator: operator,
          target_type: targetType,
          target_data: targetData,
          target_value: targetValue,
        },
      });
  }, [
    index,
    sourceData,
    sourceField,
    fieldType,
    operator,
    targetType,
    targetData,
    targetValue,
    onChange,
  ]);

  const onHandleTargetValue = (value) => {
    setTargetValue(value);
  };

  const onHandleVariableTarget = useCallback(
    (kind, value) => {
      if (kind === "data") setTargetData(value);
      if (kind === "value") setTargetValue(value);
    },
    [targetData, targetValue]
  );

  const onHandleRangeTargetValue = useCallback(
    (index, value) => {
      const currentValue = targetValue;
      if (Array.isArray(targetValue)) {
        currentValue[index] = value;
        setTargetValue([...currentValue]);
      }

      if (typeof targetValue === "string") {
        const newValue = ["", ""];
        newValue[index] = value;
        setTargetValue(newValue);
      }
    },
    [targetValue]
  );

  useEffect(() => {
    if (targetValue && rule?.target_value !== targetValue) {
      onHandleOnChange();
    }
  }, [targetValue, rule?.target_value, onHandleOnChange]);

  return (
    <RuleEngineWrapper className={className}>
      {showClearButton && (
        <CloseButtonWrapper>
          <a
            href="#"
            onClick={(e) => {
              e.preventDefault();
              onHandleClose();
            }}
          >
            Clear Filter
          </a>
        </CloseButtonWrapper>
      )}
      {showConditionWrapper && (
        <ConditionWrapper>
          <div>{ruleOperator}</div>
        </ConditionWrapper>
      )}

      <RuleEngineRow className={className} isMobile={isMobile} isTablet={isTablet}>
        <RuleSelector
          dataTestId={"dropdown-source-data-id"}
          suffix={"In"}
          options={origins}
          defaultValue={sourceData}
          onSelect={(origin) => {
            if (sourceData !== origin.value) {
              setSourceData(origin.value);
            }
          }}
        />
        <RuleSelector
          dataTestId={"dropdown-source-field-id"}
          suffix={"If"}
          options={filteredFields}
          defaultValue={sourceField}
          onSelect={(field) => {
            if (sourceField !== field.value) {
              setSourceField(field.value);
            }
          }}
        />
        <RuleSelector
          dataTestId={"dropdown-operator-id"}
          options={filterOperators}
          defaultValue={operator}
          onSelect={(operator) => {
            if (operator !== operator.value) {
              setOperator(operator.value);
            }
          }}
        />

        <RuleSelector
          dataTestId={"dropdown-listDestine-id"}
          options={
            operator === "BETWEEN"
              ? [{ name: "Constant", value: "CONSTANT" }]
              : listDestine
          }
          defaultValue={targetType}
          onSelect={(destineValue) => setTargetType(destineValue.value)}
        />
      </RuleEngineRow>
      <RuleEngineRow className={className}>
        {targetType === "VARIABLE" && (
          <>
            <RuleSelector
              dataTestId={"dropdown-variable-origin-id"}
              suffix={"In"}
              options={origins}
              onSelect={(originValue) =>
                onHandleVariableTarget("data", originValue.value)
              }
              defaultValue={targetData}
            />
            <RuleSelector
              dataTestId={"dropdown-variable-field-id"}
              options={filteredFieldsVariable}
              onSelect={(value) => onHandleVariableTarget("value", value.value)}
              defaultValue={targetValue}
            />
          </>
        )}
        {targetType === "CONSTANT" &&
          !["IN", "NOT_IN", "BETWEEN"].includes(operator) &&
          !["DATE", "TIME"].includes(fieldType) && (
            <RuleInput
              dataTestId={"constant-field-value-id"}
              onChange={(inputValue) => onHandleTargetValue(inputValue)}
              defaultValue={targetValue}
              type={fieldType}
            />
          )}
        {targetType === "CONSTANT" && ["IN", "NOT_IN"].includes(operator) && (
          <TagsComponent
            dataTestId={"constant-tags-value-id"}
            onChange={(tags) => onHandleTargetValue(tags)}
            defaultValue={targetValue}
          />
        )}
        {targetType === "CONSTANT" &&
          operator === "BETWEEN" &&
          !["DATE", "TIME"].includes(fieldType) && (
            <>
              <RuleInput
                dataTestId={"constant-field-value-start-id"}
                type={fieldType}
                defaultValue={
                  Array.isArray(targetValue) && targetValue.length
                    ? targetValue[0]
                    : ""
                }
                onChange={(inputValue) =>
                  onHandleRangeTargetValue(0, inputValue)
                }
              />
              <RuleInput
                dataTestId={"constant-field-value-end-id"}
                type={fieldType}
                defaultValue={
                  Array.isArray(targetValue) && targetValue.length
                    ? targetValue[1]
                    : ""
                }
                onChange={(inputValue) =>
                  onHandleRangeTargetValue(1, inputValue)
                }
              />
            </>
          )}
        {targetType === "CONSTANT" &&
          operator !== "BETWEEN" &&
          ["DATE", "TIME"].includes(fieldType) && (
            <DateTimePickerCustom
              dataTestId={"constant-datetime-picker-id"}
              value={formatReceiveDateTime(targetValue, fieldType)}
              onChange={(inputValue) =>
                onHandleTargetValue(formatDateTime(inputValue, fieldType))
              }
              type={fieldType}
            />
          )}
        {targetType === "CONSTANT" &&
          operator === "BETWEEN" &&
          ["DATE", "TIME"].includes(fieldType) && (
            <>
              <DateTimePickerCustom
                dataTestId={"constant-datetime-picker-start-id"}
                value={formatReceiveDateTime(targetValue?.[0], fieldType)}
                onChange={(inputValue) =>
                  onHandleRangeTargetValue(
                    0,
                    formatDateTime(inputValue, fieldType)
                  )
                }
                type={fieldType}
              />
              <DateTimePickerCustom
                dataTestId={"constant-datetime-picker-end-id"}
                value={formatReceiveDateTime(targetValue?.[1], fieldType)}
                onChange={(inputValue) =>
                  onHandleRangeTargetValue(
                    1,
                    formatDateTime(inputValue, fieldType)
                  )
                }
                type={fieldType}
              />
            </>
          )}
      </RuleEngineRow>
    </RuleEngineWrapper>
  );
};

export default RuleEngine;

import styled from "styled-components"

const ChatQuestionsWrapper = styled.section`
    border-radius: 8px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 0 10px; // Add padding for smaller screens
    margin-bottom: 10%;
`;

const ChatQuestionsBox = styled.div`
    width: 100%;
    padding: 10px 0;
    margin-top: ${props => props.$mt || '0px'};
    max-width: 600px; // Limit width on larger screens
`;

const ChatQuestionsBoxDescription = styled.span`
    color: ${props => props.theme.colors.greyTwo};
    font-size: 14px; // Slightly smaller for mobile
    text-align: justify;
    font-style: italic;
    
    @media (min-width: 768px) {
        font-size: 15px;
    }
`;

const ChatQuestionsBoxTitle = styled.span`
    color: ${props => props.theme.colors.greyTwo};
    font-size: 14px; // Slightly smaller for mobile
    text-align: justify; 
    font-weight: bold;
    
    @media (min-width: 768px) {
        font-size: 15px;
    }
`;

const ChatQuestionsSuggestions = styled.div`
    margin-top: 20px;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 10px;
`;

const Suggestion = styled.div`
    flex: 1 1 100%; // Full width on mobile
    min-height: 33px;
    background: ${props => props.theme.colors.highClearGray};
    border-radius: 12px;
    text-align: center;
    font-size: 0.875rem; // 14px
    font-weight: bold;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px;
    cursor: pointer;
    transition: background-color 0.5s;
    line-height: 1.2;

    &:hover {
        background-color: ${props => props.theme.colors.lightGrey};
    }

    @media (min-width: 480px) {
        flex-basis: calc(50% - 10px);
        font-size: 0.9375rem; // 15px
    }

    @media (min-width: 768px) {
        font-size: 1rem; // 16px
    }
`;

const Suggestion2 = styled(Suggestion)`
    // Inherits styles from Suggestion
`;

const EditAnswerInput = styled.textarea`
    resize: none;
    padding: 5px 10px;
    border-radius: 10px;
    width: 100%; // Full width on all screens
    max-width: 600px; // Limit width on larger screens

    &:focus {
        outline: none;
    }
`;  

const ChatQuestionsInputContainer = styled.div`
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 5px; 
    width: 100%;
`;

const ChatQuestionsInputContainerLeftText = styled.div`
    width: 100%;
    text-align: left;
    padding: 0 5px; // Add some padding
`;

export {
    ChatQuestionsWrapper,
    ChatQuestionsBox,
    ChatQuestionsBoxDescription,
    ChatQuestionsSuggestions,
    Suggestion,
    ChatQuestionsBoxTitle,
    EditAnswerInput,
    Suggestion2,
    ChatQuestionsInputContainer,
    ChatQuestionsInputContainerLeftText
}
import styled from "styled-components";

const NotesDrawerTriggerWrapper = styled.div`
  display: ${(props) => (props.isOpen ? "none" : "flex")};
  position: absolute;
  bottom: 35px;
  left: calc(100% - 30px);
  width: 45px;
  height: 45px;
  display: flex; 
  justify-content: center;
  align-items: center;
`;

export { NotesDrawerTriggerWrapper };

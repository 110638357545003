const actions = {
    appointment_schedule: {
        icon: 'appointmentSchedule',
        title: 'Schedule appointment',
        description: 'Coordinates and organizes appointments based on user availability and specific requirements, ensuring efficient time management.',
        superAdminOnly: true,
    },
    send_sms: {
        icon: 'sms',
        title: 'Send sms',
        description: 'Send messages to communicate information or interact with others.',
    },
    send_email: {
        icon: 'email',
        title: 'Send email',
        description: 'Send emails to communicate information or interact with other people.',
    },
    get_inventory: {
        icon: 'inventory',
        title: 'Inventory',
        description: 'Collect and organize relevant information in a clear way for the audience.',
    },
    repair_schedule: {
        icon: 'repairSchedule',
        title: 'Repair Schedule',
        description: 'Facilitates the scheduling of repairs, managing requests and assigning available technicians according to service priority.',
        superAdminOnly: true,
    },
    generate_human_in_the_loop_alert: {
        icon: 'humanLoop',
        title: 'Human in the loop Alert',
        description: 'Sends notifications or alerts to involve a human operator when manual intervention is required, ensuring informed decisions in critical situations.',
        superAdminOnly: true,
    },
    follow_up: {
        icon: 'followUp',
        title: 'Follow Up',
        description: 'Proactively follows up on previous actions to ensure compliance, gather feedback, and provide relevant updates.',
        superAdminOnly: true,
    },
};

export {
  actions,
}
import React, { useEffect, useState, useRef, useMemo, useCallback } from "react";
import dayjs from "dayjs";
import Markdown from "react-markdown";
import PropTypes from 'prop-types';
import classNames from "classnames";
import {
  TimelineWrapper,
  DateWrapper,
  MessageWrapper,
  CollapsableWrapper,
  ErrorMessage,
  DateSenderWrapper,
} from "./TimelineBox.styles";
import Icon from "../../../../../elements/icon/Icon";
import { useStore } from "../../../../../hooks";
import Spinner from "../../../../../components/common/Spinner";
import MessageTimelineContent from "./MessageTimelineContent";
import { AGENT_ROLES } from "../../../../../utils/enums";
import { observer } from "mobx-react";

const groupMessagesByDate = (messages) => {
  const grouped = {};
  messages.forEach((msg) => {
    const date = dayjs(msg.insert_date).format("YYYY-MM-DD");
    if (!grouped[date]) grouped[date] = [];
    grouped[date].push(msg);
  });
  return grouped;
};

const getInitials = (name) => name.charAt(0).toUpperCase();

const TimelineBox = observer(() => {
  const { agentStore, authStore, agentTreeStore } = useStore();
  const [collapsed, setCollapsed] = useState({});
  const [localTimelineMessages, setLocalTimelineMessages] = useState([]);
  const containerRef = useRef(null);
  const { assistanceUCPStore } = useStore();
  const { timelineMessages, isCustomerMessagesError, isLoadingMessages } = assistanceUCPStore;

  const getAgentById = async (agentId) => {
    let totalAgents = [...agentTreeStore.agents];
    const accountId = authStore.selectedAccount.id;

    if (!totalAgents.length) {
      const request = await agentTreeStore.getAgents(accountId);
      totalAgents = request;
    }
    
    const searchAndFindAgentById = totalAgents.find((agent) => agent.id === agentId);

    if (searchAndFindAgentById.length) { 
      return searchAndFindAgentById;
    }

    if (!searchAndFindAgentById?.length) {
      const getIndividualAgentById = await agentStore.getAgentById(accountId, agentId);
      return getIndividualAgentById;
    }

    return {
      name: agentId
    }
  }

  useEffect(() => {
    if (timelineMessages.length > 0) {
      setLocalTimelineMessages(timelineMessages);
    }
  }, [timelineMessages]);

  const groupedMessages = useMemo(() => groupMessagesByDate(localTimelineMessages), [localTimelineMessages]);

  const toggleCollapse = useCallback((id) => {
    setCollapsed((prev) => ({ ...prev, [id]: !prev[id] }));
  }, []);

  return (
    <TimelineWrapper ref={containerRef}>
      {isCustomerMessagesError ? (
        <ErrorMessage>
          <p>Unable to fetch conversation history at the moment. Please try again later.</p>
        </ErrorMessage>
      ) : isLoadingMessages ? (
        <ErrorMessage>
          <Spinner size="large" />
        </ErrorMessage>
      ) : (
        <>
          {Object.keys(groupedMessages).map((date) => (
            <div key={date}>
              <DateWrapper>
                <span>{dayjs(date).format("DD/MM/YYYY")}</span>
              </DateWrapper>
              {groupedMessages[date].map((msg) => {
                const isAgent = AGENT_ROLES.some(role => msg.sender.toLowerCase().includes(role));
                const isEmail = msg.channel?.toLowerCase() === "email";

                const messageWrapperClasses = classNames({
                  'message-container': true,
                  'agent-message': isAgent,
                  'user-message': !isAgent,
                  'email-message': isEmail
                });

                const avatarClasses = classNames('assistant-avatar', {
                  'email-mode': isEmail
                });

                return (
                  <MessageWrapper
                    key={msg.id}
                    isAgent={isAgent}
                    isEmail={isEmail}
                    className={messageWrapperClasses}
                  >
                    {isAgent && (
                      <div
                        className={avatarClasses}
                        data-tooltip={msg.channel}
                      >
                        A
                      </div>
                    )}
                    {isAgent && isEmail ? (
                      <CollapsableWrapper>
                        <div
                          className={classNames('email-header', {
                            'expanded': collapsed[msg.id]
                          })}
                          data-tooltip={msg.channel}
                          onClick={() => toggleCollapse(msg.id)}
                        >
                          <div
                            className={classNames('sender-info', {
                              'with-tooltip': true
                            })}
                            data-tooltip={msg.channel}
                          >
                            <Icon
                              name="email"
                              height="15px"
                              width="15px"
                              className="icon"
                            />
                            <span className="sender-email">
                              {`<${msg.sender}>`}
                            </span>
                          </div>

                          <DateSenderWrapper>
                            <span className="email-date">
                              {dayjs(msg.insert_date).format("MM/DD/YYYY")}
                            </span>
                            <button
                              className={classNames('collapse-button', {
                                'expanded': collapsed[msg.id]
                              })}
                              aria-expanded={!collapsed[msg.id]}
                              aria-controls={`email-body-${msg.id}`}
                            >
                              <Icon
                                name={collapsed[msg.id] ? "arrowUp" : "expandMoreOutlinedBlack"}
                                width="9px"
                                height="9px"
                                margin="0 12px"
                              />
                            </button>
                          </DateSenderWrapper>
                        </div>

                        <div
                          className={classNames('email-body', {
                            'collapsed': !collapsed[msg.id]
                          })}
                          id={`email-body-${msg.id}`}
                        >
                          <strong>RE: {msg.subject || ""}</strong>
                          <Markdown>{msg.message}</Markdown>
                        </div>
                      </CollapsableWrapper>
                    ) : (
                      <MessageTimelineContent
                        getAgentById={getAgentById}
                        msg={msg}
                        isAgent={isAgent}
                        className={classNames('message-content', {
                          'agent': isAgent,
                          'user': !isAgent
                        })}
                      />
                    )}
                    {!isAgent && (
                      <div className={classNames('avatar', 'user-avatar')}>
                        {getInitials(msg.sender)}
                      </div>
                    )}
                  </MessageWrapper>
                );
              })}
            </div>
          ))}
        </>
      )}
    </TimelineWrapper>
  );
});

TimelineBox.propTypes = {
  timelineMessages: PropTypes.array.isRequired,
  isCustomerMessagesError: PropTypes.bool.isRequired,
  isLoadingMessages: PropTypes.bool.isRequired,
};

export default TimelineBox;

import React, { useState, forwardRef, useRef } from "react";
import PropTypes from "prop-types";
import { Input as BaseInput } from "@mui/base/Input";
import ArrowUpward from "@mui/icons-material/ArrowUpward";
import AttachFile from "@mui/icons-material/AttachFile";
import { IconButton as CustomIconButton, SuccessfulModal } from "..";
import QuestionAnswerOutlinedIcon from '@mui/icons-material/QuestionAnswerOutlined';
import { Tooltip } from '@mui/material';
import AdmirationSign from '../../elements/icon/assets/admiration_sign.svg';
import Icon from '../../elements/icon/Icon';
import {
  ChatInputWrapper,
  FileAttachmentWrapper,
  DragAndDropIndicator,
  InputButton,
  InputRoot,
  InputElement,
  IconButton,
  InputAdornment,
  StopButton,
  ListIconWrapper,
  SendButton,
  AiHelpButton,
} from "./ChatInput.styled";
import { API } from "aws-amplify";
import { getUploadUrlFile } from "../../graphql/queries";
import axios from 'axios';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';

const Input = forwardRef(function CustomInput(props, ref) {
  const { slots, ...other } = props;
  return (
    <BaseInput
      slots={{
        root: InputRoot,
        textarea: InputElement,
        ...slots,
      }}
      {...other}
      ref={ref}
      multiline={true}
    />
  );
});

Input.propTypes = {
  slots: PropTypes.shape({
    input: PropTypes.elementType,
    root: PropTypes.elementType,
    textarea: PropTypes.elementType,
  }),
};

const defaultPlaceholder = "Message ANN Agent...";
export default function InputAdornments({
  onChange,
  onKeyPress,
  showSpinner,
  newMessage = "",
  handleSendMessage,
  wrapperStyles = {},
  className = {},
  onFileUploaded,
  textAreaRef,
  placeholder = defaultPlaceholder,
  showFileIcon = true,
  customSendMessageIcon,
  handleCustomIconClick,
  width,
  disableSend,
  onCleanThread = () => {},
  showNewChatButton = true,
  customerId,
  stop = false,
  onStopClick = () => { },
  topRightNotesComponent = null, 
}) {
  const fileInputId = Math.random();
  const extensions = [
    "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
    "text/plain",
    "application/pdf",
    "text/csv",
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  ];

  const [fileUploaded, setFileUploaded] = useState();
  const [errorPopup, setErrorPopup] = useState("");
  const [droppingFile, setDroppingFile] = useState(false);
  const [uploading, setUploading] = useState(false);
  const [anchorEl, setAnchorEl] = useState(null);
  const open = Boolean(anchorEl);

  const fileInputRef = useRef();

  const removeSpecialCharacters = (string) => {
    const withoutSpaces = string.replace(/\s/g, "_");
    const withoutSpecialChars = withoutSpaces.replace(/[^.\w\s]/gi, "");
    return withoutSpecialChars;
  };

  const handleFile = async (files) => {
    if (files.length) {
      const fileToUpload = files[0];

      if (!extensions.includes(fileToUpload.type)) {
        return setErrorPopup(
          "Please upload a valid format file:*.doc, *.docx, *.pdf, *.txt, *.csv, *.xlsx.",
        );
      }

      if (fileToUpload.size / 1e6 > 100) {
        return setErrorPopup(`File exceeds the 100 mb file size limit.`);
      }
      const file = files[0];

      try {
        setUploading(true);
        const response = await API.graphql({
          query: getUploadUrlFile,
          variables: {
            input: {
              customer: customerId,
              file_name: removeSpecialCharacters(file.name),
              file_type: file.type,
              file_route: "assistants_attachments",
            },
          },
          authMode: "AMAZON_COGNITO_USER_POOLS",
        });

        const uploadUrl = JSON.parse(response.data.getUploadUrlFile?.body);

        const config = {
          headers: {
            "Content-Type": file.type,
          },
        };

        await axios.put(uploadUrl, file, config);

        const newUrl = uploadUrl
          .replace("https", "s3")
          .replace(".s3.amazonaws.com", "")
          .split("?")[0];

        onFileUploaded(newUrl);
        setFileUploaded(file.name);
      } catch(err) {
        setErrorPopup(`Please try again.`);
      } finally {
        setUploading(false);
      }
    }
  };

  const onRemoveFile = () => {
    setFileUploaded("");
    onFileUploaded({});
    fileInputRef.current.value = "";
  };

  const handleCleanThread = () => {
    onCleanThread();
  };

  const handleEmailGeneration = () => {
    handleSendMessage("Generate a draft email about this conversation");
    handleClose();
  };

  const handleSmsGeneration = () => {
    handleSendMessage("Generate a draft SMS about this conversation");
    handleClose();
  };

  const handleSummaryGeneration = () => {
    handleSendMessage("Please provide a summary of this conversation");
    handleClose();
  };

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <ChatInputWrapper
      className={className}
      wrapperStyles={wrapperStyles}
      width={width}
    >
      {topRightNotesComponent && topRightNotesComponent}
      {fileUploaded && (
        <FileAttachmentWrapper>
          <span>{fileUploaded}</span>
          <CustomIconButton
            icon="close"
            width="15px"
            height="15px"
            onClick={onRemoveFile}
            dataTestId="file-remove-button"
          />
        </FileAttachmentWrapper>
      )}

      {droppingFile && (
        <DragAndDropIndicator>
          Drop file to attach to message...
        </DragAndDropIndicator>
      )}

      {uploading && (
        <DragAndDropIndicator>Attaching file...</DragAndDropIndicator>
      )}

      <Input
        placeholder={placeholder}
        value={newMessage}
        ref={textAreaRef}
        onChange={onChange}
        onKeyPress={(e) => {
          if (!uploading) onKeyPress(e, setFileUploaded);
        }}
        disabled={showSpinner}
        onDragOver={(e) => {
          e.preventDefault();
          setDroppingFile(true);
        }}
        onDragLeave={(e) => {
          e.preventDefault();
          setDroppingFile(false);
        }}
        onDrop={(e) => {
          e.preventDefault();
          setDroppingFile(false);
          handleFile(e.dataTransfer.files);
        }}
        startAdornment={
          showFileIcon && (
            <InputAdornment>
              <Tooltip title="AI Help">
                <InputButton
                  onClick={handleClick}
                  aria-controls={open ? 'ai-help-menu' : undefined}
                  aria-haspopup="true"
                  aria-expanded={open ? 'true' : undefined}
                  as={AiHelpButton}
                >
                  <Icon name="iconSms" width="20px" height="20px" dataTestId="ai-help-tooltip"/>
                </InputButton>
              </Tooltip>
              <input
                id={`contained-button-file-${fileInputId}`}
                ref={fileInputRef}
                type="file"
                style={{ display: "none" }}
                onChange={(e) => handleFile(e.target.files)}
                data-testid="file-input-chat"
              />
              {showNewChatButton && (
                <Tooltip title="New chat">
                  <InputButton>
                    <QuestionAnswerOutlinedIcon
                     data-testid="new-chat" onClick={() => handleCleanThread(true)}
                    />
                  </InputButton>
                </Tooltip>
              )}
              <Menu
                id="ai-help-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                MenuListProps={{
                  'aria-labelledby': 'ai-help-button',
                }}
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'left',
                }}
                transformOrigin={{
                  vertical: 'bottom',
                  horizontal: 'left',
                }}
              >
                <MenuItem onClick={handleEmailGeneration}>
                  <ListItemIcon>
                    <ListIconWrapper>
                      <Icon name="iconEmail" className="email-icon" />
                    </ListIconWrapper>
                  </ListItemIcon>
                  <ListItemText>Generate Email</ListItemText>
                </MenuItem>
                <MenuItem onClick={handleSmsGeneration}>
                  <ListItemIcon>
                    <ListIconWrapper>
                      <Icon name="iconEmail" className="sms-icon" />
                    </ListIconWrapper>
                  </ListItemIcon>
                  <ListItemText>Generate SMS</ListItemText>
                </MenuItem>
                <MenuItem onClick={handleSummaryGeneration}>
                  <ListItemIcon>
                    <Icon name="iconEmail" width="20px" height="20px" />
                  </ListItemIcon>
                  <ListItemText>Summarize Conversation</ListItemText>
                </MenuItem>
              </Menu>
              <InputButton htmlFor={`contained-button-file-${fileInputId}`}>
                <AttachFile />
              </InputButton>
            </InputAdornment>
          )
        }
        endAdornment={
          <InputAdornment>
           {stop ? (
              <StopButton onClick={onStopClick}
              data-testid="stop-button">
                <span></span>
              </StopButton>
            ) : (
              <IconButton
                onClick={() => {
                  if (customSendMessageIcon) {
                    handleCustomIconClick();
                  } else {
                    handleSendMessage(newMessage.trim() === "" ? null : newMessage);
                  }
                  setFileUploaded("");
                }}
                color="primary"
                  disabled={showSpinner || disableSend || uploading}
                  data-testid="send-button"
                  sx={{backgroundColor: 'black', color: 'white', borderRadius: '5px', width: '30px', ":disabled": {"backgroundColor": "grey"}}}>
                  {customSendMessageIcon ? customSendMessageIcon : <ArrowUpward />}
              </IconButton>
            )}
          </InputAdornment>
        }
      />
      <SuccessfulModal
        isOpen={!!errorPopup}
        onClose={() => setErrorPopup("")}
        imageSrc={AdmirationSign}
        title="Oops!"
        subtitle="An error occurred."
        subtitle2={errorPopup}
        height="300px"
        width="500px"
        zIndex={true}
      />
    </ChatInputWrapper>
  );
}

InputAdornments.propTypes = {
  onChange: PropTypes.func.isRequired,
  onKeyPress: PropTypes.func.isRequired,
  showSpinner: PropTypes.bool.isRequired,
  newMessage: PropTypes.string,
  handleSendMessage: PropTypes.func.isRequired,
  wrapperStyles: PropTypes.object,
  className: PropTypes.object,
  onFileUploaded: PropTypes.func.isRequired,
  textAreaRef: PropTypes.object,
  placeholder: PropTypes.string,
  showFileIcon: PropTypes.bool,
  customSendMessageIcon: PropTypes.node,
  handleCustomIconClick: PropTypes.func,
  width: PropTypes.string,
  disableSend: PropTypes.bool,
  onCleanThread: PropTypes.func,
  showNewChatButton: PropTypes.bool,
  stop: PropTypes.bool,
  onStopClick: PropTypes.func,
};

import { useState } from "react";
import {
    GenericListContainer,
    Header,
    Title
} from "../GenericList/GenericList.styled";
import RoundedIconButton from "../Buttons/RoundedIconButton/RoundedIconButton";
import ActionItem from "./ActionItem";
import ActionLibrary from "./components/ActionLibrary";
import { actions } from "./consts";
import { observer } from "mobx-react";
import { useStore } from "../../../hooks";

const ActionsList = observer(({agentActions, onSave, addAction= true}) => {
    const {authStore} = useStore()
    const [openLib, setOpenLib] = useState(false);

    return (
        <div> 
            <Header>
                <Title>Actions</Title>
                { addAction && (
                        <RoundedIconButton icon={'circleAdd'} width='24px' height='24px' onClick={() => setOpenLib(true)}/>
                    )
                }
            </Header>
            <GenericListContainer>
              {agentActions && agentActions.map((actionId, idx) => {
                const action = actions[actionId];
                return (
                  action && (
                    <ActionItem
                      key={idx}
                      icon={action.icon}
                      title={action.title}
                      subtitle={action.description}
                    />
                  )
                );
              })}
            </GenericListContainer>

            <ActionLibrary isOpen={openLib} onClose={() => setOpenLib(false)} initialAgentActions={agentActions} onSave={onSave} isAdmin={authStore.isGroupAdmin} isSuperAdmin={authStore.isGroupSuperAdmin}/>
        </div>
    )
})

export default ActionsList
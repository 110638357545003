import styled, { createGlobalStyle } from "styled-components";

const CustomGenericListStyle = createGlobalStyle`
  #main-content {
    overflow: hidden;
  }
`;

const StyledBox = styled.div`
  display: flex;
  flex-direction: column;
  height: ${({ isMobile, isTablet, isDesktop }) => {
    if (isMobile || isTablet) return "70vh";
    if (isDesktop) return "85vh";
    return "80vh";
  }};
  padding: 15px;
`;


export {
  CustomGenericListStyle,
  StyledBox
};

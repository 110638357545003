import { useMediaQuery } from "react-responsive";

export const useMediaQueries = () => {
  const isMobile = useMediaQuery({ query: "(max-width: 767px)" });

  const isIpadAirPortrait = useMediaQuery({ query: "(min-width: 768px) and (max-width: 820px) and (max-height: 1180px)" });

  const isIpadAirLandscape = useMediaQuery({ query: "(min-height: 768px) and (max-height: 820px) and (max-width: 1180px)" });

  const isIpadProPortrait = useMediaQuery({ query: "(min-width: 821px) and (max-width: 1024px) and (max-height: 1366px)" });

  const isIpadProLandscape = useMediaQuery({ query: "(min-height: 821px) and (max-height: 1024px) and (max-width: 1366px)" });

  const isTablet = useMediaQuery({
    query: "(min-width: 768px) and (max-width: 1024px) and (not (width: 1180px))",
  });

  const isDesktop = useMediaQuery({ query: "(min-width: 1025px)" });

  return {
    isMobile,
    isIpadAirLandscape,
    isIpadProLandscape,
    isIpadAirPortrait,
    isIpadProPortrait,
    isTablet,
    isDesktop,
  };
};
import styled from "styled-components";

const ViewWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 20px 0px;
  width: 100%;
  height: 83vh;
  gap: 20px;
  margin-top: ${(props) => (props.isMobile ? "20px" : "0px")};
`;

const RulesWrapper = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: 8px;
  border: 1px solid ${(props) => props.theme.colors.clearGray};
  background: var(--White, #FFF);
`;

const RulesHeaderWrapper = styled.div`
  padding: 16px;
  border-bottom: 1px solid ${(props) => props.theme.colors.clearGray};
`;

const RulesMessageWrapper = styled.div`
  display: flex;
  flex-direction: row;
  padding: 16px;
  color: ${(props) => props.theme.colors.dark};
  font-size: 0.875rem;
  background: ${(props) => props.theme.colors.lightRed};
  ${(props) => props.theme.fonts.variant.semiBold()};
  gap: 8px;
`;

const RulesFooterWrapper = styled.div`
  display: flex;
  padding: 5px;
  flex-direction: column;
  align-items: flex-start;
  gap: 10px;
  align-self: stretch;

  border-top: 1px solid ${(props) => props.theme.colors.clearGray};
`;

const RulesContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  flex: 1 0 0;
`;

const RulesHeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const ClearLink = styled.a`
  color: ${(props) => props.theme.colors.activeBlue};
  font-size: 12px;
  cursor: pointer;
  margin-right: ${(props) => (props.isMobile ? "0px" : "5px")};
  margin-top: ${(props) => (props.isMobile ? "45px" : "0px")};
  position: ${(props) => (props.isMobile ? "absolute" : "relative")};
`;

export {
  ViewWrapper,
  RulesWrapper,
  RulesHeaderWrapper,
  RulesMessageWrapper,
  RulesContentWrapper,
  RulesFooterWrapper,
  RulesHeaderContainer,
  ClearLink,
};

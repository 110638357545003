import React from "react";
import { useStore } from "../../../hooks";
import { useMediaQueries } from "../../../utils/mediaQueries";
import { Icon } from "../..";
import NoteAddIcon from "@mui/icons-material/NoteAdd";
import { observer } from "mobx-react";
import { NotesDrawerTriggerWrapper } from "./NotesDrawerTrigger.styled";
import { NotesFab } from "../DynamicNotes/DynamicNotes.styled";

const NotesDrawerTrigger = observer(({ children }) => {
  const { notesStore } = useStore();
  const { isMobile } = useMediaQueries();

  return (
    <>
        <NotesDrawerTriggerWrapper isOpen={notesStore.isNotesDrawerOpen}>
          <NotesFab
            isMobile={isMobile}
            onClick={() => {
              notesStore.openNotesDrawer();
            }}
            $isOpen={notesStore.isNotesDrawerOpen}
          >
            <Icon name="notesOpen" width={"13.6px"} height={"16px"} />
            <NoteAddIcon />
          </NotesFab>
        </NotesDrawerTriggerWrapper>
    </>
  );
});

export default NotesDrawerTrigger;

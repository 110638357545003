import React, { useEffect, useState } from 'react';
import {
    ModalWrapper,
    ModalButtonsContainer,
    Backdrop,
    ModalContainer,
    Title,
    ModalHeaderContainer,
    GenericListContainer
} from "./ActionLibrary.styled";
import { RoundedButton } from '../../../button';
import ActionItem from '../ActionItem';
import { actions } from '../consts';

const ActionLibrary = ({ isOpen, onClose, onSave, initialAgentActions, isAdmin, isSuperAdmin }) => {
    const handleClose = () => {
        onClose();
    };

    const [availableActions, setAvailableActions] = useState(Object.keys(actions));
    const [agentActions, setAgentActions] = useState([]);

    useEffect(() => {
        if(initialAgentActions && initialAgentActions.length){
            setAgentActions(initialAgentActions);
            setAvailableActions(availableActions.filter(action => !initialAgentActions.includes(action)));
        }
    }, [initialAgentActions])

    const switchAction = (origin, setOrigin, destination, setDestination, idx, value) => {
        const currentList = [...origin];
        currentList.splice(idx, 1);
        setOrigin(currentList);

        setDestination([...destination, value])
    }

    const addAction = (idx, action) => switchAction(availableActions, setAvailableActions, agentActions, setAgentActions, idx, action);
    const removeAction = (idx, action) => switchAction(agentActions, setAgentActions, availableActions, setAvailableActions, idx, action);

    const handleSave = () => {
        onSave(agentActions);
        handleClose();
    }

    return (
        <Backdrop isOpen={isOpen}>
            <ModalWrapper>
                <ModalHeaderContainer>
                    <Title>Actions Library</Title>
                    <ModalButtonsContainer>
                        <RoundedButton
                            weight='400'
                            width={'80px'}
                            borderRadius={'8px'}
                            kind={'secondary'}
                            onClick={() => handleClose()}
                        >
                            Cancel
                        </RoundedButton>
                        <RoundedButton
                            weight='400'
                            width={'80px'}
                            borderRadius={'8px'}
                            kind={'primary'}
                            onClick={handleSave}
                        >
                            Save
                        </RoundedButton>
                    </ModalButtonsContainer>
                </ModalHeaderContainer>
                <ModalContainer>

                    <div>
                        <Title>Available actions</Title>
                        <GenericListContainer>
                            {availableActions.map((actionId, idx) => {
                                const action = actions[actionId];
                                return (
                                  action &&
                                  ((action.superAdminOnly && isSuperAdmin) || !action.superAdminOnly || isAdmin) && (
                                    <ActionItem
                                      key={idx}
                                      icon={action.icon}
                                      title={action.title}
                                      subtitle={action.description}
                                      onClick={() => addAction(idx, actionId)}
                                    />
                                  )
                                );
                            })}
                        </GenericListContainer>
                    </div>
                    <div>
                        <Title>Agent actions</Title>
                        <GenericListContainer>
                            {agentActions.map((actionId, idx) => {
                                const action = actions[actionId];
                                return (
                                  action && (
                                    <ActionItem
                                      key={idx}
                                      icon={action.icon}
                                      title={action.title}
                                      subtitle={action.description}
                                      onClick={() => removeAction(idx, actionId)}
                                    />
                                  )
                                );
                            })}
                        </GenericListContainer>
                    </div>
                </ModalContainer>
            </ModalWrapper>
        </Backdrop>
    );
};

export default ActionLibrary;

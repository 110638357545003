import React, { useEffect, useState } from "react";
import Markdown from "react-markdown";
import Icon from "../../../../../elements/icon/Icon";
import PropTypes from 'prop-types';
import dayjs from "dayjs";
import classNames from "classnames";

const MessageTimelineContent = ({ msg, isAgent, getAgentById }) => {

  const [agentName, setAgentName] = useState("...");

  useEffect(() => {
    const fetchAgentName = async () => {
      try {
        const agent = await getAgentById(msg.sender);
        setAgentName(agent.name);
      } catch (error) {
        setAgentName(msg.sender);
      }
    };
    fetchAgentName();
  }, [msg.sender, isAgent, getAgentById]);

  const messageContentClasses = classNames('message-content', {
    'agent-message': isAgent,
    'user-message': !isAgent
  });

  const messageHeaderClasses = classNames('message-header', {
    'with-tooltip': isAgent,
    'has-icon': isAgent
  });

  const senderNameClasses = classNames('sender-name', {
    'agent-sender': isAgent,
    'user-sender': !isAgent
  });

  const messageBodyClasses = classNames('message-body', {
    'agent-body': isAgent,
    'user-body': !isAgent
  });

  return (
    <div className={messageContentClasses}>
      <div
        className={messageHeaderClasses}
        data-tooltip={msg.channel}
      >
        <div className={senderNameClasses}>
          {agentName}
        </div>
        {isAgent && ( 
          <Icon
            name="messageIcon"
            height="15px"
            width="15px"
            className={classNames('icon', 'message-icon')}
          />
        )}
      </div>
      <div className={messageBodyClasses}>
        <Markdown>{msg.message}</Markdown>
      </div>
      <div className={classNames('time-stamp', {
        'agent-timestamp': isAgent,
        'user-timestamp': !isAgent
      })}>
        {dayjs(msg.insert_date).format("HH:mm")}
      </div>
    </div>
  );
};

MessageTimelineContent.propTypes = {
  msg: PropTypes.shape({
    sender: PropTypes.string.isRequired,
    channel: PropTypes.string,
    message: PropTypes.string.isRequired,
    insert_date: PropTypes.string.isRequired,
  }).isRequired,
  isAgent: PropTypes.bool.isRequired,
};

export default MessageTimelineContent;

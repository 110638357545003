import { API } from "aws-amplify";
import { action, makeObservable, observable, flow, toJS } from "mobx";
import {
  getImage,
  getUploadUrlFile,
  getUrlFile,
  getVehicleStockFilter,
  getVehicleStockImages,
  getVehicleStockPictures,
  putImage,
} from "../graphql/queries";
import axios from "axios";
import {DefaultFieldVariables, DefaultVariables, OriginVariables} from "../utils/enums";

class TemplateEditorStore {
  isError = false;
  isLoading = false;
  initialLoading = false;

  variables = [
    {
      name: "",
      variable: "",
      origin: OriginVariables.FIXED,
      value: "",
    },
  ];

  from = "";
  sender_name = "";
  to = "";
  subject = "";
  cc = "";
  bcc = "";
  description = "";
  payload = {};
  templateJson = null;
  updatingTemplate = false;
  customer = "";
  id = "";
  template = "";
  body = "";
  sourceList = [];
  s3FileRoute = "email_templates";
  vehicleStockImages = {};
  vehicleStockImagesTotalRowCount = 0;

  constructor() {
    makeObservable(this, {
      isLoading: observable,
      isError: observable,
      initialLoading: observable,
      variables: observable,
      from: observable,
      sender_name: observable,
      to: observable,
      subject: observable,
      cc: observable,
      bcc: observable,
      description: observable,
      payload: observable,
      templateJson: observable,
      updatingTemplate: observable,
      customer: observable,
      id: observable,
      template: observable,
      body: observable,
      sourceList: observable,
      s3FileRoute: observable,
      vehicleStockImages: observable,
      vehicleStockImagesTotalRowCount: observable,
      newVariable: action,
      updateVariableName: action,
      updateVariableVariable: action,
      updateVariableType: action,
      updateVariableValue: action,
      removeVariable: action,
      updateGeneralField: action,
      updateVariableValueDynamic: action,
      savePayload: action,
      saveTemplate: flow,
      uploadFileToS3: flow,
      loadTemplate: flow,
      clearTemplate: action,
      loadPayload: action,
      onClearError: action,
      setDefaultVariables: action,
      setId: action,
      getVehicleStockImages: flow,
      getVehiclesStockPicturesRequest: flow,
      getVehiclesStockPicturesFilters: flow,
      putFileName: flow,
      getImageRequest: flow
    });
  }

  *putFileName(props) {
    const { account, display_name, gallery_name, attributes, metadata } = props;
    try {
      const response = yield API.graphql({
        query: putImage,
        variables: {
          input: {
            account,
            display_name,
            gallery_name,
            attributes: attributes || [],
            metadata: JSON.stringify({})
          }
        },
        authMode: "AMAZON_COGNITO_USER_POOLS",
      });
      return JSON.parse(response.data?.putImage?.body);
    } catch (error) {
      this.isError = true;
    }
  }

  *getVehiclesStockPicturesFilters({ search, filters }) {
    try {
      const response = yield API.graphql({
        query: getVehicleStockFilter,
        variables: {
          input: {
            search: search || "",
            filters: {
              make: filters?.make || [],
              model: filters?.model || [],
              year: filters?.year || [],
              type: filters?.type || [],
              angle: filters?.angle || [],
              background: filters?.background || [],
              size: filters?.size || [],
            },
          },
        },
        authMode: "AMAZON_COGNITO_USER_POOLS",
      });
      return JSON.parse(response.data?.getVehicleStockFilter?.body);
    } catch (error) {
      this.isError = true;
    }
  }

  *getVehiclesStockPicturesRequest(parameters) {
    const {
      page_size,
      page,
      order_by,
      search,
      filters: {
        make,
        model,
        year,
        type,
        angle,
        background,
        size,
      }
    } = parameters;

    try {
      const response = yield API.graphql({
        query: getVehicleStockPictures,
        variables: {
          input: {
            parameters: {
              count_records: true,
              page_size,
              page,
              order_by: {
                id: order_by.id,
                desc: order_by.desc,
              },
              search,
              filters: {
                make,
                model,
                year,
                type,
                angle,
                background,
                size,
              },
            },
          },
        },
        authMode: "AMAZON_COGNITO_USER_POOLS",
      });

      return JSON.parse(response.data?.getVehicleStockPictures.body);

    } catch (error) {
      this.isError = true;
    }
  }

  setId(value) {
    this.id = value;
  }

  updateGeneralField(field, value) {
    this[field] = value;
  }

  setDefaultVariables(){
    const existingVariables = new Set(this.variables.map(v => v.variable));

    const addVariable = (dependencies) => {
      const { name, variable, origin, source = null, field = null } = dependencies;
      if (!name || !variable || !origin) {
        this.isError = true;
        return;
      }
      this.newVariable(name, variable, origin, '', source, field);
    };

    DefaultVariables.forEach(({ name, variable, origin, field }) => {
      if (!existingVariables.has(variable)) {
        if (variable === DefaultFieldVariables.CUSTOMER_NAME) {
          const sourceWithMatchField = this.sourceList.find(source =>
            source.fields.some(fieldData => fieldData.field === field)
          );

          if (sourceWithMatchField) {
            const fieldFound = sourceWithMatchField.fields.find(f => f.field === field);
            addVariable({
              name,
              variable,
              origin,
              source: sourceWithMatchField.origin,
              field: field
            });
          } else {
            addVariable({
              name,
              variable,
              origin
            });
          }
        } else {
          addVariable({
            name,
            variable,
            origin
          });
        }
      }
    });
  }

  newVariable(
    name = "",
    variable = "",
    origin = OriginVariables.FIXED,
    value = "",
    source = null,
    field = null
  ) {
    this.variables.push({
      name,
      variable,
      origin,
      value,
      _value: { source, field },
    });
  }

  updateVariableName(idx, value) {
    this.variables[idx].name = value;
  }

  updateVariableVariable(idx, value) {
    this.variables[idx].variable = value;
  }

  updateVariableType(idx, value) {
    this.variables[idx].origin = value;
  }

  updateVariableValue(idx, value) {
    this.variables[idx].value = value;
  }

  updateVariableValueDynamic(idx, value, source = false) {
    if (!Object.keys(this.variables[idx]._value || []).length)
      this.variables[idx]._value = {};
    if (source) {
      this.variables[idx]._value["source"] = value;
    } else {
      this.variables[idx]._value["field"] = value;
    }
    this.variables[idx].value = `${this.variables[idx]._value["field"]}`;
  }

  removeVariable(idx) {
    this.variables.splice(idx, 1);
  }

  savePayload() {
    const bindingArray = this.variables.map((variable) => ({
      name: variable.name,
      variable: `{{${variable.variable}}}`,
      origin: variable.origin,
      value: variable.value,
    }));

    const payloadArray = [
      { field: "from", value: this.from },
      { field: "sender_name", value: this.sender_name },
      { field: "to", value: this.to },
      { field: "subject", value: this.subject },
      { field: "cc", value: this.cc },
      { field: "bcc", value: this.bcc },
      { field: "description", value: this.description },
      { field: "body", value: `${this.id}.html` },
      { field: "template", value: `${this.id}.json` },
    ];

    this.payload = {
      binding: bindingArray,
      payload: payloadArray,
    };
  }

  loadPayload(action) {
    this.id = action.id;
    const payload = action?.payload;
    if (payload?.length) {
      payload.forEach((field) => {
        this[field.field] = field.value;
      });
    }

    if (action?.binding?.length) {
      action.binding.forEach((binding) => {
        binding.variable = binding.variable.replaceAll("{", "");
        binding.variable = binding.variable.replaceAll("}", "");

        if (binding.name === "") {
          return;
        }

        const [source, field] = binding.value?.split(".");
        const existInDefaultVariables = this.variables.findIndex((variable) => variable.name === binding.name);
        if (existInDefaultVariables > 0) {
          this.variables[existInDefaultVariables].value = binding.value;

          if (binding.origin === OriginVariables.EVENT) {
            this.variables[existInDefaultVariables]._value = { source, field };
          }
        } else {
          if (binding.origin === OriginVariables.EVENT) {
            binding.value = binding.value || "";
            binding._value = { source, field };
          }

          this.variables.push(binding);
        }
      });
    }
  }

  *loadTemplate() {
    if (!this.template) return;
    try {
      this.isLoading = true;
      this.isError = false;

      const response = yield API.graphql({
        query: getUrlFile,
        variables: {
          input: {
            customer: this.customer,
            file_name: `${this.template}`,
            file_route: this.s3FileRoute,
          },
        },
        authMode: "AMAZON_COGNITO_USER_POOLS",
      });

      const uploadUrl = JSON.parse(response.data.getUrlFile?.body);
      
      const res = yield axios({
        url: uploadUrl,
        method: "GET",
        responseType: "blob",
      });
      const blobRes = new Blob([res.data]);
      const streamer = yield blobRes.text();

      this.templateJson = JSON.parse(streamer);

    } catch (error) {
      this.templateJson = {};
    } finally {
      this.isLoading = false;
    }
  }

  *uploadFileToS3(content, type, name, file_route=null) {
    const blobFile = new Blob([content], { type });

    const response = yield API.graphql({
      query: getUploadUrlFile,
      variables: {
        input: {
          customer: this.customer,
          file_name: name,
          file_type: type,
          file_route: file_route || this.s3FileRoute,
          overwrite: true,
        },
      },
      authMode: "AMAZON_COGNITO_USER_POOLS",
    });

    const uploadUrl = JSON.parse(response.data.getUploadUrlFile?.body);
    const config = {
      headers: {
        "Content-Type": type,
      },
    };

    yield axios.put(uploadUrl, blobFile, config);
    return uploadUrl;
  }

  *getImageRequest(accountId) {
    try {
      this.isError = false;
      const response = yield API.graphql({
        query: getImage,
        variables: {
          input: {
            account: accountId,
            gallery_name: "unlayer_editor",
            last_evaluated_key: null
          },
        },
        authMode: "AMAZON_COGNITO_USER_POOLS",
      });

      return JSON.parse(response.data?.getImage?.body);

    } catch (error) {
      this.isError = true;
    }
  }

  *saveTemplate(html, json) {
    try {
      this.updatingTemplate = true;
      this.isError = false;
      yield this.uploadFileToS3(
        JSON.stringify(json, null, 2),
        "application/json",
        `${this.id}.json`
      );
      yield this.uploadFileToS3(html, "text/html", `${this.id}.html`);
    } catch (error) {
      this.isError = true;
    } finally {
      this.updatingTemplate = false;
    }
  }

  *getVehicleStockImages({ count_records, page_size, page, search }) {
    try {
      this.isError = false;
      const response = yield API.graphql({
        query: getVehicleStockImages,
        variables: {
          input: {
            parameters: {
              count_records: count_records,
              page_size: page_size,
              page: page,
              search: search,
            },
          },
        },
        authMode: "AMAZON_COGNITO_USER_POOLS",
      });

      this.vehicleStockImages = JSON.parse(
        response.data?.getVehicleStockImages?.body
      );
      if (count_records) {
        this.vehicleStockImagesTotalRowCount =
          this.vehicleStockImages.totalRowCount;
      }
    } catch (error) {
      this.vehicleStockImages = {};
    }
  }

  clearTemplate() {
    this.isError = false;
    this.isLoading = false;
    this.updatingTemplate = false;
    this.initialLoading = false;
    this.s3FileRoute = "email_templates";
    this.vehicleStockImages = {};
    this.vehicleStockImagesTotalRowCount = 0;

    this.template = "";
    this.body = "";
    this.variables = [
      {
        name: "",
        variable: "",
        origin: OriginVariables.FIXED,
        value: "",
      },
    ];
    this.from = "";
    this.sender_name = "";
    this.to = "";
    this.subject = "";
    this.cc = "";
    this.bcc = "";
    this.description = "";
    this.payload = {};
    this.templateJson = null;
  }

  onClearError() {
    this.isError = false;
  }

}

export default TemplateEditorStore;

import styled, { createGlobalStyle } from "styled-components";

const AssistantWrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

const AssistantBox = styled.div`
  width: 100%;
  border-radius: 10px;
  background-color: ${(props) => props.theme.colors.highClearGray};
  box-sizing: border-box;
`;

const ChatConversationWrapper = styled(AssistantBox)`
  background-color: ${(props) => props.theme.colors.white};
  padding: 0;
  flex: 1;
  overflow-x: hidden;
  overflow-y: auto;
  max-height: ${(props) => props.$maxHeight};
`;

const RichTextWrapper = styled(AssistantBox)`
  position: relative;
  padding: 16px;
  background: white !important;
`;

const RichTextWrapperTopActions = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 5px;
  height: auto;
`;

const RichTextWrapperTopActionsLeft = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
`;

const RichTextWrapperSendButton = styled.div`
  position: absolute;
  bottom: 35px;
  right: 23px;
  width: 90px;
  z-index: 3;
`;

const AttachmentButtonWrapper = styled.div`
  position: absolute;
  top: 30px;
  left: 0px;
  z-index: 4;
  display: flex;
  align-items: flex-end;
  gap: 5px;
  padding: 0 24px;
`;

const AttachmentButton = styled.label`
  cursor: pointer;
  padding: 6px;
  display: flex;
  border-radius: 10px;
  background: transparent;
`;

const FileAttachmentWrapper = styled.div`
  padding: 5px 15px;
  max-width: 100%;
  border-radius: 10px;
  border: 1px solid;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
  background-color: white;

  span {
    width: 90%;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
  }

  button {
    margin-top: 2px;
  }
`;

const SendButtonWrapper = styled.div`
  cursor: pointer;
  position: absolute;
  top: 30px;
  right: 25px;
  z-index: 1000;
`;

const ChatInputWrapper = styled.div`
  display: flex;
  flex-direction: column;
  padding: 18px;
  position: relative;

  & > div {
    width: 100%;
  }

  .suggestions-container {
    position: absolute;
    bottom: 100%;
    right: 18px;
    z-index: 9999;
    margin-bottom: 8px;

    @media (max-width: 768px) {
      width: calc(100% - 36px);
      right: 18px;
      left: 18px;
    }
  }
`;

const CustomGenericListStyle = createGlobalStyle`
  #root > iframe{
    display: none !important;
  }
  iframe#jsd-widget {
    display: none;
  }
`;

const TabsChatContainer = styled.div`
  display: flex;
  padding: 1rem;
`;

const TimeLineMessagesWrapper = styled.div`
  margin: 3rem 0;
  text-align: center;
  border-bottom: 1px solid ${(props) => props.theme.colors.davysGrey};
  line-height: 0.1em;

  & span {
    background: ${(props) => props.theme.colors.white};
    padding: 0 10px;
    color: ${(props) => props.theme.colors.davysGrey};
    font-weight: normal;
  }
`;

export {
  AssistantWrapper,
  ChatConversationWrapper,
  RichTextWrapper,
  RichTextWrapperTopActions,
  RichTextWrapperTopActionsLeft,
  RichTextWrapperSendButton,
  AttachmentButtonWrapper,
  AttachmentButton,
  FileAttachmentWrapper,
  CustomGenericListStyle,
  SendButtonWrapper,
  ChatInputWrapper,
  TabsChatContainer,
  TimeLineMessagesWrapper,
};

import { observer } from "mobx-react";
import { useStore } from "../../../hooks";
import { Icon, RoundedButton } from "../../../elements";
import RuleEngine from "./components/RuleEngine/RuleEngine";
import RuleChoiceGroup from "./components/RuleEngine/RuleChoiceGroup";
import {
  ViewWrapper,
  RulesWrapper,
  RulesHeaderWrapper,
  RulesMessageWrapper,
  RulesContentWrapper,
  RulesFooterWrapper,
  RulesHeaderContainer,
  ClearLink
} from "./RulesView.styled";
import { useCallback, useEffect, useState } from "react";
import SimpleSelect from "../../../elements/v2/Selects/SimpleSelect";
import { useMediaQueries } from "../../../utils/mediaQueries";
import { options } from "./EventName/Constants/enum";

const RulesView = observer(() => {
  const { viewConversationStore } = useStore();
  const { rulesPage } = viewConversationStore;
  const [ruleGroupdID, setRuleGroupID] = useState("");
  const { isMobile } = useMediaQueries();

  useEffect(() => {
    if (!Object.keys(rulesPage.rulesGroups).length) {
      rulesPage.clearAllRules();
    }
    setRuleGroupID(Object.keys(rulesPage.rulesGroups)[0]);
  }, [Object.keys(rulesPage.rulesGroups).length]);

  const onHandlePickOperator = useCallback(
    (operator) => {
      rulesPage.setOperator(operator.value);
    },
    [rulesPage]
  );

  const onAddCondition = () => {
    if (!ruleGroupdID) {
      rulesPage.clearAllRules();
      setRuleGroupID(Object.keys(rulesPage.rulesGroups)[0]);
    }
    rulesPage.addRuleToGroup(ruleGroupdID);
    viewConversationStore.updateChangesDetected(true);
  };


  const handleSelect = (name, value, prevSelected) => {};

  const clearAllConditions = () => {
    rulesPage.clearAllRules();
    viewConversationStore.updateChangesDetected(false);
  };

  return (
    <ViewWrapper isMobile={isMobile}>
      <RulesHeaderContainer>
        <SimpleSelect
          options={options}
          placeholder="Source"
          handleSelect={handleSelect}
        />
        <ClearLink
          isMobile={isMobile}
          href="#"
          onClick={(e) => {
            e.preventDefault();
            clearAllConditions();
          }}
        >
          Clear All Conditions
        </ClearLink>
      </RulesHeaderContainer>
      <RulesWrapper>
        <RulesHeaderWrapper>
          <RuleChoiceGroup
            dataTestId={"rule-choice-group-id"}
            disable={
              rulesPage.rulesGroups[ruleGroupdID]?.conditions.length === 0
            }
            defaultValue={rulesPage.operator || "AND"}
            options={[
              { name: "And", value: "AND" },
              { name: "Or", value: "OR" },
            ]}
            onChange={onHandlePickOperator}
          />
        </RulesHeaderWrapper>
        {rulesPage.message !== "" && (
          <RulesMessageWrapper>
            <Icon name="warning" width={"20px"} height={"20px"} />
            {rulesPage.message}
          </RulesMessageWrapper>
        )}
        {rulesPage.rulesGroups[ruleGroupdID]?.conditions.length > 0 && (
          <RulesContentWrapper>
            {rulesPage.rulesGroups[ruleGroupdID]?.conditions.map(
              (rule, index) => (
                <RuleEngine
                  rule={rule}
                  index={index}
                  key={`rule-${index}`}
                  fields={rulesPage.fields}
                  ruleOperator={rulesPage.operator || "AND"}
                  onChange={(info) =>
                    rulesPage.updateRule(info.index, info.data, ruleGroupdID)
                  }
                  onClose={() => rulesPage.removeRule(index, ruleGroupdID)}
                  showClearButton={true}
                  showConditionWrapper={true}
                />
              )
            )}
          </RulesContentWrapper>
        )}
        <RulesFooterWrapper>
          <RoundedButton width="129px" height="40px" onClick={onAddCondition} kind="secondary">
            Add condition
          </RoundedButton>
        </RulesFooterWrapper>
      </RulesWrapper>
    </ViewWrapper>
  );
});

export default RulesView;

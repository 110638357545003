import styled from 'styled-components';
import { ButtonsContainer } from "../HomePage.styled"; // Add this import

export const TabsContainer = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%
`;

export const TabsHeader = styled.div`
    display: flex;
    gap: 0.25rem;
    padding: 1px 0px;
    border-bottom: ${(props) => (props.showBorder ? '1px solid' : 'none')};
    border-bottom-color: ${(props) => props.theme.colors.silverGrayTwo};
    flex-direction: row;
    justify-content: space-between;  
    margin-bottom: 0.75rem;
    flex-wrap: wrap;

    @media (min-width: 768px) {
        gap: 0.625rem;
        margin-bottom: 1.25rem;
        flex-wrap: nowrap;
    }
`;

export const TabWrapper = styled.div`
    order: 0;
    width: 100%;
    display: flex;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    
    & > div {
        max-height: 37px;
        font-size: 0.75rem;
        flex-shrink: 0;
    }

    @media (min-width: 768px) {
        width: calc(100% - 400px);
        overflow-x: visible;

        & > div {
            font-size: 0.875rem;
        }
    }
`;

export const TabSearchContainer = styled.div`
    order: 1;
    flex: 1 1 auto;
    align-self: auto;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    width: 100%;
    margin-top: 0.5rem;

    @media (min-width: 768px) {
        order: 0;
        width: auto;
        margin-top: 0;
    }
`;

export const Tab = styled.div`
    padding: 9px 12px;
    cursor: pointer;
    border-bottom: 2px solid ${(props) => (props.isActive ? props.theme.colors.activeBlue : 'transparent')};
    color: ${(props) => (props.isActive ? props.theme.colors.activeBlue : props.theme.colors.davysGrey)};
    ${(props) => props.theme.fonts.variant.regular()};
    font-size: 0.875em;
    line-height: normal;
    font-weight: bold;
    white-space: nowrap;
    
    transition: color 0.3s, border-bottom 0.3s;
    
    &:hover {
      color: ${(props) => props.theme.colors.activeBlue};
    }

    @media (min-width: 768px) {
        padding: 9px 16px;
        font-size: 1em;
    }
`;

export const TabContent = styled.div`
    padding: 0px;
    display: flex;
    flex: 1;
    flex-direction: column;
    justify-content: space-between;
    overflow-y: auto;
    -webkit-overflow-scrolling: touch;
  
    &::-webkit-scrollbar {
      width: 3px;
    }
  
    &::-webkit-scrollbar-track {
      background: ${(props) => props.theme.colors.primaryWhite};
    }
  
    &::-webkit-scrollbar-thumb {
      background: ${(props) => props.theme.colors.silverGrayTwo};
    }

    @media (min-width: 768px) {
        &::-webkit-scrollbar {
            width: 5px;
        }
    }
`;

export const ResponsiveButtonsContainer = styled(ButtonsContainer)`
  @media (max-width: 767px) {
    display: none;
  }
`;